<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
            <div class="infoBox">
              <van-cell-group>
                <van-field readonly
                           v-model="parseFloat(UserInfo.balance).toFixed(3)"
                        :label="$t('buy.label[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                />
                <van-field readonly
                        v-model="CardInfo.name"
                        :label="$t('buy.label[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                />
                <van-field readonly
                        v-model="CardInfo.card_no"
                        :label="$t('buy.label[2]')"
                        :placeholder="$t('bindAccount.placeholder[2]')"
                />
                <van-field readonly
                           v-model="parseFloat(CoinInfo.price).toFixed(3)"
                        :label="$t('buy.label[3]')"
                        :placeholder="$t('bindAccount.placeholder[3]')"
                />
                <van-field readonly
                        v-model="parseFloat(CoinInfo.bestPrice).toFixed(3)"
                        @click="showUsdtType = true"
                        :label="$t('buy.label[4]')"
                        :placeholder="$t('bindAccount.placeholder[4]')"
                />
                <div style="display: flex;background-color: #191C23;">
                <van-field required
                        v-model="BankInfo.price"
                        :label="$t('buy.label[5]')"
                        :placeholder="$t('buy.placeholder[0]')"
                />
                  <van-button color="#0076FA" size="min" style="width: 100px" @click="setPrice">
                    <span>{{$t('buy.label[7]')}}</span></van-button>
                </div>
                <div style="display: flex">
                <van-field required
                        v-model="BankInfo.total_number"
                        :label="$t('buy.label[6]')"
                        :placeholder="$t('buy.placeholder[1]')"
                />
                </div>
                <div style="display: flex;background-color: #191C23;">
                  <van-field required
                          v-model="BankInfo.min_number"
                          :label="$t('buy.label[10]')"
                          :placeholder="$t('buy.placeholder[2]')"
                  />-
                  <van-field required border
                          v-model="BankInfo.max_number"
                          :placeholder="$t('buy.placeholder[3]')"
                  />
                </div>
              </van-cell-group>
            </div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="onSubmit">{{ $t('buy.label[9]') }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      CardInfo:{},
      BankInfo:{price:null,total_number:null,min_number:null,max_number:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()
    this.$Model.GetBankCardList(data=>{
      this.CardInfo = data.data[0];
    });
    this.$Model.GetCommonData(data=>{
      let currs = data.currencylist;
      currs.forEach(item=>{
        if (item['name']=="USDT"){
          this.CoinInfo = item;
          this.CoinInfo['bestPrice'] = item['price'];
        }
      })
    });
    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    setPrice(){
      this.BankInfo.price=this.CoinInfo.bestPrice
    },
    setAmount(){
      this.BankInfo.total_number=this.UserInfo.balance;
    },
    onSubmit() {
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (this.BankInfo.total_number<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      } else if (this.BankInfo.min_number<=0||this.BankInfo.max_number<=0||this.BankInfo.min_number>this.BankInfo.max_number){
        this.$Dialog.Toast(this.$t('mytask2[10]'));
        return;
      }
      this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 1;
      this.$Model.SubmitTask(
              this.BankInfo,
              (data) => {
                let msg = '';
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -2:
                    msg = this.$t('mytask2[8]');
                    break;
                  case -3:
                    msg = this.$t('mytask2[9]');
                    break;
                }
                this.$Dialog.Toast(msg);
                if (data.code==1){
                  this.$router.push("/hall");
                }
              }
      );
    },
    onChangeType(obj,value){
      this.UserInfo.usdttype = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
  .PageBox {
    color: #d7d7d7;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#fff;
  }

  .infoBox {
    width: 95%;
    margin-left: 10px;
  }


  .van-cell {
    background-color: #191C23;
  }

.van-cell>>>.van-cell__title{
  font-size: 14px;
}


.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
  margin: 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}

  .btn {
    width: 85%;
    padding: 10px 50px;
    border-radius: 15px;
    background-color: #0076FA;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 30px 30px;
  }
</style>
